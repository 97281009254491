.Theta-Drop-Main{
    display: inline-block;
    text-align: center;
    margin:auto;
    background-color: #ff238b;
    width: 100vw;
    min-height: 100vh;
}

.Theta-Drop-image-container img{
    margin:auto;
    width: 25%;
}

.Theta-Drop-Main h3{
    font-weight: 900;
    font-size: 4rem;
}

.Theta-Drop-button-container{
    width: 80vw;
    text-align: center;
    margin: auto;
}

.Theta-Drop-button-container button{
    min-width: 50vw;
    border-radius: 15px;
    background-color: rgba(41, 0, 83, 0.9);
    color: white;
    border: none;
    font-size: 2.5rem;
    font-weight: 900;
    padding: 10px;
    margin: 10px;
}


.video-container {
    position: relative;
    width: 50vw;
    margin: 0 auto;
  }
  
  .video-player {
    width: 50vw;
    height: auto;
    margin: 10px;
    -webkit-user-select: none; /* Disable text selection on webkit */
    -moz-user-select: none;    /* Disable text selection on Firefox */
    -ms-user-select: none;     /* Disable text selection on IE */
    user-select: none;         /* Disable text selection on standard browsers */
  }